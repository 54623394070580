import { Home } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ActionButton } from "../../components/button/SubmitButton";
import { Tooltip } from "@mui/material";
import { USER_ROLES } from "../../utils/constants";
import { useSelector } from "react-redux";

const WFHRaiseButton = () => {
  const { user } = useSelector((state) => state);
  return (
    <>
      {/* <Tooltip  title="Coming soon..." > */}
      {(user.data.department == "64782051d2aa5788df891efc" ||
        user.data.role == USER_ROLES.hr) && (
        <ActionButton
          LinkComponent={Link}
          icon={<Home color="inherit" />}
          title={"Apply WFH"}
          to="raise-wfh"
        ></ActionButton>
      )}
      {/* </Tooltip> */}
    </>
  );
};
export default WFHRaiseButton;
