import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import fileDownload from "js-file-download";
import moment from "moment";
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../../utils/constants";
import { getReimberesementCSVApi } from "../../../apis/reimbersement.api";
import SubmitButton from "../../../components/button/SubmitButton";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";

const DownloadSalaaryStructureSlipCsv = ({ date }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState();

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getReimberesementCSVApi({ date: date.toISOString() }),
        (response) => {
          fileDownload(
            response,
            moment(date).format("MMM-YYYY") + "-reimberesement.csv"
          );
          setLoading(false);
        },
        (err) => {
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          setLoading(false);
        },
        true
      )
    );
  };
  if (
    user.data.role == USER_ROLES.hr ||
    user.data.role == USER_ROLES.admin ||
    user.data.department === "66222ed244219a58597d83cf"
  )
    return (
      <>
        <SubmitButton
          onClick={fetchList}
          loading={loading}
          title={"Download Csv"}
        />
      </>
    );
};
export default DownloadSalaaryStructureSlipCsv;
