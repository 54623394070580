import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  deductLwpLeave,
  getDailyUpdateByParentIdAndDepartment,
  getDailyUpdateByUser,
  getDepartment,
  updateDailyUpdateStatus,
} from "../../apis/dailyupdate.api";
import { EmployeeTaskUserContext } from "./EmployeeMain";
import EmployeeDailyUpdateUi from "./EmployeeDailyUpdateUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { actions } from "../../utils/constants";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { Typography } from "@mui/material";

const EmployeeDailyUpdateController = () => {
  const [date, setDate] = useState(moment());
  const [departments, setDepartments] = useState([]);
  const [dailyupdateWithDepartment, setDailyupdateWithDepartment] = useState(
    []
  );

  const [loading, setLoading] = useState(false);
  const [fetchMoreloading, setFetchMoreloading] = useState(false);
  const selectedUser = useContext(EmployeeTaskUserContext);
  const dispatch = useDispatch();

  const [isReject, setIsReject] = useState([]);
  const { user } = useSelector((state) => state);
  const [leaveOnDate, setLeaveOnDate] = useState([]);
  const abortControllerRef = useRef(null);
  const [expanded, setExpanded] = useState([]);
  const [isLwpData, setIsLwpData] = useState([]);
  const [isCompanyHoliday, setIsCompanyHoliday] = useState([]);
  const [debouncedDate, setDebouncedDate] = useState(date);
  const [selectedUserDailyUpdate, setSelectedUserDailyUpdate] = useState([]);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [rejectId, setRejectId] = useState("");

  const deductLWPButton = (user) => {
    dispatch(
      callApiAction(
        async () => await deductLwpLeave({ userId: user?._id, date }),
        (response) => {
          dispatch(callSnackBar("mail Send Successfully", "success"));
          setIsLwpData((prev) => {
            let temp = prev;
            temp = [...temp, user._id];
            return temp;
          });
          dispatch(closeModal());
        },
        (err) => {
          dispatch(callSnackBar(err, "error"));
        }
      )
    );
  };

  const onDeduct = (user) => {
    console.log("this is user details.....", user);
    dispatch(
      openModal({
        title: "Alert!",
        component: (
          <Typography>
            {"Are You sure you want to Deduct LWP of " + user.name + " ?"}
          </Typography>
        ),
        onConfirm: () => {
          dispatch({ type: actions.LOADING_TRUE });
          deductLWPButton(user);
        },
        size: "sm",
        confirmText: "Deduct",
        onCancle: () => dispatch(closeModal()),
      })
    );
  };

  const onReject = (data) => {
    setRejectLoading(true);
    setRejectId(data.task_id);
    dispatch(
      callApiAction(
        async () =>
          await updateDailyUpdateStatus({
            ...data,
          }),
        (response) => {
          setRejectLoading(false);
          setIsReject((prev) => {
            let temp = prev;
            temp = [...temp, data?.task_id];
            return temp;
          });
          setRejectId("");
          dispatch(callSnackBar("Daily Update Rejected", "success"));
        },
        (err) => {
          setRejectLoading(false);
          setRejectId("");
          dispatch(callSnackBar(err, "error"));
        }
      )
    );
  };

  const fetchDailyUpdateForSelectedUser = () => {
    dispatch(
      callApiAction(
        async () =>
          await getDailyUpdateByUser({ user_id: selectedUser._id, date }),
        (response) => {
          setSelectedUserDailyUpdate(response);
        },
        (err) => {
          dispatch(callSnackBar(err, "error"));
        }
      )
    );
  };

  useEffect(() => {
    if (selectedUser) fetchDailyUpdateForSelectedUser();
  }, [selectedUser, date]);

  useEffect(() => {
    setLoading(true);
    setFetchMoreloading(true);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const handler = setTimeout(() => {
      setDebouncedDate(date);
    }, 100);

    return () => {
      clearTimeout(handler);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [date]);

  useEffect(() => {
    setLoading(true);
    setFetchMoreloading(true);
    fetchDepartment();
  }, []);

  const fetchDailyUpdateByDepartment = async () => {
    const currentController = new AbortController();
    abortControllerRef.current = currentController;
    try {
      for (let id of departments) {
        const response = await getDailyUpdateByParentIdAndDepartment(
          {
            parent_id: user?.data?._id,
            date: date,
            department: id._id._id,
          },
          currentController.signal
        );

        setIsCompanyHoliday((prev) => {
          let temp = prev;
          temp = [...temp, ...response?.data?.isCompanyHoliday];
          return temp;
        });

        setDailyupdateWithDepartment((prev) => {
          let temp = [
            ...prev,
            {
              department: id?._id?.name,
              users: response?.data?.data,
              department_id: id?._id?._id,
            },
          ];
          return temp;
        });

        if (loading) {
          setLoading(false);
        }

        if (
          fetchMoreloading &&
          departments.indexOf(id) == departments.length - 1
        ) {
          setFetchMoreloading(false);
        }
      }
      if (loading) {
        setLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const currentController = new AbortController();
    abortControllerRef.current = currentController;
    setDailyupdateWithDepartment([]);
    setExpanded([]);
    setIsLwpData([]);
    setIsCompanyHoliday([]);
    fetchDailyUpdateByDepartment();
  }, [departments, debouncedDate]);

  const fetchDepartment = async () => {
    try {
      const response = await getDepartment({
        parent_id: user?.data?._id,
      });
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching users by parent ID:", error);
      return [];
    }
  };

  return (
    <EmployeeDailyUpdateUi
      date={date}
      dailyupdateWithDepartment={dailyupdateWithDepartment}
      setDate={setDate}
      departments={departments}
      loading={loading}
      onReject={onReject}
      fetchMoreloading={fetchMoreloading}
      leaveOnDate={leaveOnDate}
      isReject={isReject}
      isLwpData={isLwpData}
      onDeduct={onDeduct}
      expanded={expanded}
      setExpanded={setExpanded}
      isCompanyHoliday={isCompanyHoliday}
      selectedUserDailyUpdate={selectedUserDailyUpdate}
      rejectLoading={rejectLoading}
      rejectId={rejectId}
    />
  );
};

export default EmployeeDailyUpdateController;