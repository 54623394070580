import {
  getAdvancePaymentsListApi,
  getPendingReimberesementApi,
  getReimberesementApi,
  getReimberesementCountApi,
} from "../../apis/reimbersement.api";
import { actions } from "../../utils/constants";
import { callApiAction } from "./commonAction";

export const fetchAdvancePaymentDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_REIMBURSEMENT_ADVANCE_PAYMENT_LOADING });
    dispatch(
      callApiAction(
        async () => await getAdvancePaymentsListApi(filters),
        (response) => {
          dispatch({
            type: actions.SET_REIMBURSEMENT_ADVANCE_PAYMENT_DATA,
            value: { data: response, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};
export const fetchPendingReimbursementDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_REIMBURSEMENT_PENDING_LOADING });
    const passFilters = { ...filters };
    dispatch(
      callApiAction(
        async () => await getPendingReimberesementApi({ ...passFilters }),
        (response) => {
          dispatch({
            type: actions.SET_REIMBURSEMENT_PENDING_DATA,
            value: { data: response, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const fetchReimbursementCountAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_REIMBURSEMENT_COUNT_LOADING });
    const passFilters = { ...filters };
    passFilters["date"] = passFilters["date"].toISOString();

    dispatch(
      callApiAction(
        async () =>
          await getReimberesementCountApi({
            ...passFilters,
            isAccountant: true,
          }),
        (response) => {
          dispatch({
            type: actions.SET_REIMBURSEMENT_COUNT_DATA,
            value: { data: response, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(false);
        }
      )
    );
  };
};

export const fetchPersonalReimbursementDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_REIMBURSEMENT_PERSONAL_LOADING });
    const passFilters = { ...filters };
    passFilters["date"] = passFilters["date"].toISOString();
    dispatch(
      callApiAction(
        async () => await getReimberesementApi({ ...passFilters ,isAccountant:true}),
        (response) => {
          dispatch({
            type: actions.SET_REIMBURSEMENT_PERSONAL_DATA,
            value: { data: response, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(false);
        }
      )
    );
  };
};
