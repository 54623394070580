import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const createDailyUpdate = async (data) => {
  const callResponse = await axios({
    url: endpoints.dailyUpdateBase,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const getAllDailyUpdates = async (params) => {
  const callResponse = await axios({
    url: endpoints.getAllDailyUpdateOfMonth,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const getDailyUpdateOnDate = async (params) => {
  const callResponse = await axios({
    url: endpoints.dailyUpdateBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const fetchUserWiseDepartment = async (params = {}) => {
  const callResponse = await axios({
    url: endpoints.fetchUserViseDepartment,
    method: "get",
    headers: getHeaders(),
    params, // Now params will never be undefined
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response?.status || 500, // Handle undefined status
    }));

  return callResponse;
};

export const getDailyUpdateDashboard = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchDailyUpdateDashboard,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const getDepartment = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchDepartment,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const updateDailyUpdateStatus = async (data) => {
  const callResponse = await axios({
    url: endpoints.changeDailyUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};
export const getDailyUpdateByUser = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchDailyUpdateByUser,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const getDailyUpdateByParentIdAndDepartment = async (params, signal) => {
  const callResponse = await axios({
    url: endpoints.dailyUpdateByParentIdAndDepartment,
    method: "get",
    headers: getHeaders(),
    params,
    signal,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const deductLwpLeave = async (data) => {
  const callResponse = await axios({
    url: endpoints.deductLwp,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};
