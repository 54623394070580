import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Input,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { getUserApi } from "../../apis/user.api";
import AsyncDropDown from "./AsyncDropDown";
import { unEscapeStr } from "../../utils/helper";
import { fetchTeamListApi } from "../../apis/team.api";
import { useLocation } from "react-router-dom";

const StyledInput = styled(TextField)(({ theme }) => ({
  padding: theme.spacing(3),

  background: theme.palette.light.main,
  borderRadius: theme.shape.borderRadius,
  lineHeight: "100%",
  display: "flex",
  alignItems: "center",

  boxShadow: theme.shadows[1],

  "::before": {
    content: "none",
  },
  ":focus": {
    "::before": {
      content: "none",
      borderRadius: theme.shape.borderRadius,
    },
  },
  ".MuiOutlinedInput-root": {
    padding: "0px !important",
  },
  fieldset: {
    padding: "0px !important",
    border: "none !important",
  },
  ":after": {
    display: "none",
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  color: theme.palette.grey.light,
  outline: "none",
  borderRadius: theme.shape.borderRadius,

  paddingBottom: theme.spacing(3) + " !important",
}));

export const StyledSearchBar = styled(TextField)(({ theme }) => ({
  padding: theme.spacing(0),

  background: theme.palette.grey["200"],
  borderRadius: theme.shape.borderRadius,
  lineHeight: "100%",
  display: "flex",
  alignItems: "center",

  "::before": {
    content: "none",
  },
  ":focus": {
    "::before": {
      content: "none",
      borderRadius: theme.shape.borderRadius,
    },
  },
  ".MuiOutlinedInput-root": {
    padding: "0px !important",
  },
  fieldset: {
    padding: "0px !important",
    border: "none !important",
  },
  ":after": {
    display: "none",
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  color: theme.palette.grey.light,
  outline: "none",
  borderRadius: theme.shape.borderRadius,
}));

export const UserSearchBarNormal = ({
  onUserChange,
  inputProps = {},
  defaultParams = {},
  data,
  value,
  defaultVal,
  ...props
}) => {
  return (
    <AsyncDropDown
      InputComponent={(props) => (
        <TextField
          label
          placeholder="Search Manager"
          {...props}
          {...inputProps}
        />
      )}
      lazyFun={async (props) => {
        return await getUserApi({
          ...defaultParams,
          ...props,
          searchable: ["first_name", "last_name"],
          sort: "first_name",
          sortDirection: 1,
        });
      }}
      defaultVal={defaultVal}
      OptionComponent={({ option, ...rest }) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", alignItems: "center" }}
            {...rest}
          >
            <Typography ml={3} variant="h5">
              {option.name} ({option.designation})
            </Typography>
          </Box>
        );
      }}
      onChange={async (changedVal) => {
        onUserChange(changedVal);
      }}
      titleKey={"name"}
      valueKey={"_id"}
      {...props}
    />
  );
};

export const UserSearchBarNormalUser = ({
  onUserChange,
  inputProps = {},
  defaultParams = {},
  data,
  value,
  defaultVal,
  ...props
}) => {
  console.log(" user props", defaultParams);
  return (
    <AsyncDropDown
      InputComponent={(props) => (
        <TextField
          label
          placeholder="Search Manager"
          {...props}
          {...inputProps}
        />
      )}
      lazyFun={async (props) => {
        return await fetchTeamListApi({
          ...defaultParams,
          ...props,
          searchable: ["first_name", "last_name"],
          sort: "first_name",
          sortDirection: 1,
        });
      }}
      defaultVal={defaultVal}
      OptionComponent={({ option, ...rest }) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", alignItems: "center" }}
            {...rest}
          >
            <Typography ml={3} variant="h5">
              {console.log("object user list", option.first_name)}
              {option.first_name} ({option.designation})
            </Typography>
          </Box>
        );
      }}
      onChange={async (changedVal) => {
        onUserChange(changedVal);
      }}
      titleKey={"first_name"}
      valueKey={"_id"}
      {...props}
    />
  );
};

const UserSearchBar = ({ onUserChange, ...props }) => {
  const location = useLocation();

  const pathArray = location.pathname.split("/");
  console.log("pathArray", pathArray);

  return (
    <AsyncDropDown
      InputComponent={(props) => (
        <StyledInput placeholder="Search User" {...props} />
      )}
      lazyFun={async (props) =>
        await getUserApi({
          ...props,
          searchable: ["first_name", "last_name"],
          sort: "first_name",
          sortDirection: 1,
          isAccountant: pathArray.includes("reimburesement") ? true : false,
        })
      }
      startAdornment={<Search sx={{ mr: 2 }} />}
      OptionComponent={({ option, ...rest }) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", alignItems: "center" }}
            {...rest}
          >
            <Typography ml={3} variant="h5">
              {option.name} ({option.designation})
            </Typography>
          </Box>
        );
      }}
      onChange={async (changedVal) => {
        onUserChange(changedVal);
      }}
      titleKey={"name"}
      valueKey={"_id"}
      sx={{ heigh: "64px" }}
      {...props}
    />
  );
};

export default UserSearchBar;
