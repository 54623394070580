import { useEffect, useMemo, useState } from "react"
import moment from "moment"
import { Box, Chip } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import { callApiAction } from "../../store/actions/commonAction"
import { getReimberesementApi, getReimberesementCountApi } from "../../apis/reimbersement.api"
import { REIMBURSEMENT_STATUS } from "../../utils/constants"
import { findObjectKeyByValue, toTitleCase } from "../../utils/helper"
import ReimburesmentViewMoreButton from "../reimberesement/actionButtons/ReimburesementViewMoreButton"
import ReimburesementApprovalButton from "../reimberesement/actionButtons/ReimburesmentApprovalButtons"
// import ReimberesementPageUI from "./ReimberesementPageUI"
import { AddCircle, CheckCircle } from "@mui/icons-material"
import ReimburesmentCounts from "../reimberesement/ReimburesmentCounts"
import DashBox from "./Dashbox"
import { fetchReimbursementCountAction } from "../../store/actions/reimbursementAction"


const DashboardReimburesement = ({ selectedUser = {} }) => {

    const [list, setList] = useState({})


    const [loading, setLoading] = useState(false)
    const [counts, setCounts] = useState({})

    const [listLoading, setListLoading] = useState(false)

    const dispatch = useDispatch()
    const params = useParams()
    const {reimbursement, user} = useSelector((state) => state)
    const userId = user.data?._id

    
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 25,
        search: '',

        date: moment(),
        userId: userId ,
        status: null

    })
    const fetchReimburesmentCounts = () => {
        setLoading(true)

        const passFilters = { ...filters }
        passFilters['date'] = passFilters['date'].toISOString()

        dispatch(callApiAction(
            async () => await getReimberesementCountApi({ ...passFilters,isAccountant:true }),
            (response) => {
                setCounts(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    


    const getReimbursementCountData = () => {
        const reimbursementMonth = moment(reimbursement.count_filters.date).month();
        const currentMonth = moment(filters.date).month()
        if (!reimbursement.count_data || reimbursement.count_data.length === 0 || reimbursementMonth != currentMonth || reimbursement.count_filters.userId != userId) {
            dispatch(fetchReimbursementCountAction(filters));
        }
    }

    useEffect(() => {
        getReimbursementCountData()
        fetchReimburesmentCounts()
    }, [filters.date, filters.userId])

   



    return (
        <>
        <DashBox title={'Reimburesement'} padding={2} showDate={true}>
        <Box margin={3}>
            <ReimburesmentCounts
        
                data={reimbursement.count_data}
                
           
                dashboard={true}
                loading={reimbursement.count_loading}
                setFilters={setFilters}
                filters={filters}
             

            />
            </Box>
          </DashBox>
        </>
    )
}
export default DashboardReimburesement; 