import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AccountCircle, Business, Cancel } from "@mui/icons-material";
import DailyUpdateCustomDayPicker from "../../components/layouts/common/DailyUpdateCustomDayPicker";
import { EmployeeTaskUserContext } from "./EmployeeMain";
import {
  DAILYUPDATE_STATUS,
  LEAVE_STATUS,
  LEAVE_TYPE,
} from "../../utils/leave.constants";
import { useSelector } from "react-redux";
import { CenteredBox } from "../../components/layouts/common/boxes";
import { HALF_TYPE_OBJ } from "../../utils/constants";
import moment from "moment";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const EmployeeDailyUpdateUi = ({
  fetchMoreloading,
  isLwpData,
  date,
  setDate,
  loading,
  onReject,
  onDeduct,
  dailyupdateWithDepartment,
  expanded,
  setExpanded,
  isReject,
  isCompanyHoliday,
  selectedUserDailyUpdate,
  rejectLoading,
  rejectId,
  departments,
}) => {
  const selectedUser = useContext(EmployeeTaskUserContext);
  const [buttonClicked, setButtonClicked] = useState(false);

  const onExpandedChange = (id) => {
    if (expanded.includes(id)) {
      setExpanded((prev) => {
        let temp = prev;
        temp = temp.filter((val) => val !== id);
        return temp;
      });
    } else {
      setExpanded((prev) => {
        let temp = prev;
        temp = [...temp, id];
        return temp;
      });
    }
  };

  const renderUserCard = (user, key, idx) => {
    const isToday = moment().diff(moment(date), "day") < 2;
    const isFutureDate = date.isAfter(moment(), "day");
    const isWeekOf = user?.weekly_of?.includes(new Date(date)?.getDay());
    let isfirstHalfLeave = false;
    let issecondHalfLeave = false;
    let isfullLeave = false;

    user?.OnLeaveData?.map((data) => {
      if (data?.leave_count == 1) {
        isfullLeave = true;
      } else {
        if (data?.from_time_duration == HALF_TYPE_OBJ.FIRST_HALF) {
          isfirstHalfLeave = true;
        } else {
          issecondHalfLeave = true;
        }
      }
    });

    return (
      <Grid item xs={12} sm={6} md={4} key={key}>
        <Card
          sx={{
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderRadius: "12px",
            border: "1px solid #ddd",
          }}
        >
          <CardContent sx={{ overflowY: "auto", padding: "8px 12px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "4px 8px",
                borderRadius: "4px",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#f9f9f9",
                width: "100%",
                height: "40px",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccountCircle sx={{ fontSize: 20, mr: 1 }} />
                  {selectedUser ? selectedUser?.name : user?.name}
                </Typography>
              </Box>

              {user?.OnLeaveData?.length == 0 &&
                !isCompanyHoliday?.length > 0 &&
                user?.DailyUpdates?.length == 0 &&
                !isWeekOf &&
                !isToday && (
                  <Button
                    disabled={
                      user?.DeductLwp?.length > 0 ||
                      isLwpData?.includes(user._id)
                        ? true
                        : false
                    }
                    variant={buttonClicked ? "contained" : "outlined"}
                    onClick={() => {
                      onDeduct(user);
                    }}
                    sx={{
                      color: buttonClicked ? "#fff" : "#f44336",
                      borderColor: "#f44336",
                      backgroundColor: buttonClicked
                        ? "#f44336"
                        : "transparent",
                      "&:hover": {
                        backgroundColor: buttonClicked
                          ? "#f44336"
                          : "transparent",
                        borderColor: "#f44336",
                      },
                    }}
                  >
                    <Typography> LWP</Typography>
                  </Button>
                )}
            </Box>

            {user.DailyUpdates?.length > 0 ? (
              user.DailyUpdates.map((task, i) => (
                <React.Fragment key={i}>
                  <Box
                    mt={2}
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      alignItems: "center",
                      backgroundColor:
                        task?.status === DAILYUPDATE_STATUS.REJECTED ||
                        isReject.includes(task._id)
                          ? "rgba(244, 67, 54, 0.1)"
                          : "#f9f9f9",
                    }}
                  >
                    <Typography ml={2} variant="body2" sx={{ flexGrow: 1 }}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            task.description || "No description available.",
                        }}
                      />
                    </Typography>

                    <IconButton
                      size="small"
                      sx={{
                        visibility:
                          task?.status !== DAILYUPDATE_STATUS.REJECTED &&
                          !isReject.includes(task._id)
                            ? "visible"
                            : "hidden",
                      }}
                      color="error"
                      onClick={() => {
                        if (selectedUser) {
                          onReject({ task_id: task?._id });
                        } else {
                          onReject({ task_id: task?._id });
                        }
                      }}
                    >
                      {!rejectLoading ? (
                        <Cancel />
                      ) : (
                        rejectId == task?._id && (
                          <CircularProgress size={"20px"} />
                        )
                      )}
                    </IconButton>
                  </Box>

                  {i < user?.tasks?.length - 1 && <Divider sx={{ my: 1 }} />}
                </React.Fragment>
              ))
            ) : (isfirstHalfLeave && issecondHalfLeave) || isfullLeave ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "red",
                    mt: 2,
                  }}
                >
                  Employee on leave.
                </Typography>
              </Box>
            ) : isWeekOf ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#D58C37",
                    mt: 2,
                  }}
                >
                  Weekly Of.
                </Typography>
              </Box>
            ) : isCompanyHoliday.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#999",
                    mt: 2,
                  }}
                >
                  Company Holiday.
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#999",
                    mt: 2,
                  }}
                >
                  No Update Found.
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const renderSelectedUserCard = (user, key) => {
    console.log("this is user", user);
    const isToday = moment().diff(moment(date), "day") < 2;
    const isFutureDate = date.isAfter(moment(), "day");
    const isWeekOf = user?.weekly_of?.includes(new Date(date)?.getDay());

    return (
      <Grid item xs={12} sm={6} md={4} key={key}>
        <Card
          sx={{
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderRadius: "12px",
            border: "1px solid #ddd",
          }}
        >
          <CardContent sx={{ overflowY: "auto", padding: "8px 12px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "4px 8px",
                borderRadius: "4px",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#f9f9f9",
                width: "100%",
                height: "40px",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Business color="primary" sx={{ fontSize: 22, mr: 1 }} />
                  {user?.department?.name}
                </Typography>
              </Box>
            </Box>

            {user?.dailyUpdates?.length > 0 ? (
              user?.dailyUpdates.map((task, i) => (
                <React.Fragment key={i}>
                  <Box
                    mt={2}
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderRadius: "10px",
                      alignItems: "center",
                      backgroundColor:
                        task?.status === DAILYUPDATE_STATUS.REJECTED ||
                        isReject.includes(task._id)
                          ? "rgba(244, 67, 54, 0.1)"
                          : "#f9f9f9",
                    }}
                  >
                    <Typography ml={2} variant="body2" sx={{ flexGrow: 1 }}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            task?.description || "No description available.",
                        }}
                      />
                    </Typography>
                    <IconButton
                      size="small"
                      sx={{
                        visibility:
                          task?.status !== DAILYUPDATE_STATUS.REJECTED &&
                          !isReject.includes(task._id)
                            ? "visible"
                            : "hidden",
                      }}
                      color="error"
                      onClick={() => {
                        if (selectedUser) {
                          onReject({ task_id: task?._id });
                        }
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </Box>

                  {i < user?.tasks?.length - 1 && <Divider sx={{ my: 1 }} />}
                </React.Fragment>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#999",
                    mt: 2,
                  }}
                >
                  No Update Found.
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    borderRadius: "10px",
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: "rgba(0, 0, 0, .1)",

    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    flexDirection: "row",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255, 255, 255, .05)",
    }),
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(3),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "rgba(0, 0, 0, .02)",
  }));

  const renderAllUsers = () => {
    return dailyupdateWithDepartment.length > 0 ? (
      dailyupdateWithDepartment?.map((card, idx) => {
        return (
          <Box mt={3}>
            <Accordion
              expanded={!expanded.includes(card.department_id)}
              onChange={() => {
                onExpandedChange(card.department_id);
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Business color="primary" sx={{ fontSize: 22, mr: 1 }} />
                <Typography
                  variant="h4"
                  color={"primary"}
                  sx={{ fontWeight: 700 }}
                >
                  {card.department}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {card.users.length > 0 ? (
                    card.users.map((user, index) =>
                      renderUserCard(user, index, idx)
                    )
                  ) : (
                    <Grid item xs={12}></Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })
    ) : (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        mt={3}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#999",
            textAlign: "center",
          }}
        >
          No Users.
        </Typography>
      </Box>
    );
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box mt={3} mb={3} position="relative">
      {!isMobile && (
        <Box mb={2}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
              color: "#0D0D0D",
            }}
          >
            Employee Daily Updates
          </Typography>
        </Box>
      )}

      <>
        <Box id="custom-day-picker">
          <DailyUpdateCustomDayPicker date={date} setDate={setDate} />
        </Box>
      </>
      {loading ? (
        <CenteredBox sx={{ minHeight: "300px" }}>
          <CircularProgress />
        </CenteredBox>
      ) : (
        <>
          {selectedUser
            ? (() => {
                return (
                  <Box mt={4}>
                    {false ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "100px",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: "red",
                            textAlign: "center",
                          }}
                        >
                          Employee on leave.
                        </Typography>
                      </Box>
                    ) : selectedUserDailyUpdate?.length === 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "100px",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#999",
                            textAlign: "center",
                          }}
                        >
                          No update found.
                        </Typography>
                      </Box>
                    ) : (
                      <Grid container spacing={3} direction="row">
                        {selectedUserDailyUpdate?.map((user, index) =>
                          renderSelectedUserCard(user, index)
                        )}
                      </Grid>
                    )}
                  </Box>
                );
              })()
            : renderAllUsers()}
        </>
      )}
      {!selectedUser &&
        fetchMoreloading &&
        !loading &&
        departments.length > 0 && (
          <CenteredBox sx={{ minHeight: "100px" }}>
            <CircularProgress />
          </CenteredBox>
        )}
    </Box>
  );
};

export default EmployeeDailyUpdateUi;